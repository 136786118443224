<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 351 345">
        <defs>
            <linearGradient id="prefix__a" x1="0%" x2="100%" y1="100%" y2="0%">
                <stop offset="0%" stop-color="#E60000" stop-opacity=".404"/>
                <stop offset="100%" stop-color="#E60000" stop-opacity=".103"/>
            </linearGradient>
        </defs>
        <g fill="none" fill-rule="evenodd" transform="translate(-3)">
            <circle cx="181.158" cy="172.721" r="172.248" fill="url(#prefix__a)" fill-rule="nonzero"/>
            <path fill="#FDD525" fill-rule="nonzero" d="M64.154 256.585c-2.992 2.882-5.197 5.95-9.277 7.275-7.986 2.742-11.904-2.598-14.853-8.987-2.992-6.522-6.428-11.725-13.735-13.612-11.332-3.078-25.22 6.578-21.948 18.887 1.033 4.073 6.164 2.847 7.753.126 2.252-3.818 3.503-8.926 8.675-9.578 7.013-.81 8.995 6.188 11.6 11.072 2.736 5.284 6.923 9.213 12.61 11.04 9.842 2.973 23.362-2.889 22.76-14.596.05-1.633-2.353-2.762-3.585-1.627z"/>
            <path fill="#B141AD" fill-rule="nonzero" d="M278.805 231.642c.831 5.584 6.652 17.463 12.83 19.126 3.325.831 5.701-1.307 8.196-3.089 4.633-3.207 9.147-6.415 13.78-9.74 1.663-1.189 2.613-3.446 1.425-5.346-4.039-6.178-8.078-12.474-12.117-18.65-1.069-1.664-3.563-2.733-5.345-1.426-4.99 3.564-10.454 6.89-15.087 11.047-3.088 2.851.832 7.603 4.396 5.702 5.108-2.732 9.86-6.652 14.611-9.978l-5.346-1.426c4.04 6.178 8.078 12.474 12.117 18.65l1.426-5.345c-2.733 1.9-5.465 3.92-8.197 5.82-1.544 1.07-6.771 5.94-8.434 5.822-1.545-.12-4.752-5.702-5.702-6.771-1.901-2.257-3.802-4.515-6.296-6.178-1.188-.712-2.376.594-2.257 1.782z"/>
            <path fill="#FDD525" fill-rule="nonzero" d="M302.8 25.895c-10.453 4.87-20.788 10.097-31.123 15.324-2.494 1.307-2.85 3.92-.95 5.94 7.603 7.84 14.968 15.68 23.164 22.926 1.545 1.426 4.04.594 4.87-1.188 4.99-11.047 12.711-26.015 10.573-38.488-.475-3.208-5.345-4.633-6.652-1.07-2.02 5.584-2.02 11.761-3.326 17.463-1.545 6.652-3.445 13.305-6.415 19.482 1.782-.238 3.682-.356 5.464-.594-7.365-8.434-15.443-16.037-23.402-23.877 0 1.782-.118 3.682-.118 5.464 10.097-5.702 20.075-11.404 30.054-17.462 2.02-1.307.119-4.99-2.138-3.92z"/>
            <g>
                <path fill="#B63B03" fill-rule="nonzero" d="M168.914 15.745c4.454-1.322 9.313-1.932 13.87-.915 4.555 1.016 8.908 3.659 11.237 7.725 2.632 4.675 2.43 10.367 2.024 15.754l-2.43 40.251c-.202 3.761-.404 7.623 1.013 11.08 1.417 3.455 5.062 6.301 8.707 5.488 1.822-.406 3.442-1.626 5.163-2.338 1.72-.711 4.05-.711 5.163.813.607.915.709 2.135.607 3.253-.506 4.98-3.644 9.555-7.896 11.994 2.53-1.22 5.77 1.321 5.77 4.167s-2.227 5.286-4.86 6.404c-2.631 1.016-5.567 1.016-8.402 1.016 2.329 0 4.15 2.541 3.948 4.88-.202 2.337-2.024 4.268-4.252 5.183-2.126.915-4.555.915-6.884.712-4.96-.509-9.921-2.033-13.768-5.184-6.783-5.387-9.517-14.637-9.618-23.378-.101-8.742 2.025-17.28 3.24-25.92 2.834-20.023.506-40.352-1.823-60.477.608-.204 1.114-.305 1.721-.508" transform="translate(37.697 37.68)"/>
                <path fill="#FFE6D5" fill-rule="nonzero" d="M129.303 111.58l-97.86 36.728 9.36-39.476-25.432-6.511s-27.364 77.222-8.341 79.359c33.264 3.663 83.617-9.564 83.617-9.564l38.656-60.537z" transform="translate(37.697 37.68)"/>
                <path fill="#FFF" fill-rule="nonzero" d="M95.303 200.613l.204 77.762c25.541 31.473 114.73-9.197 114.73-9.197l1.022-31.779 2.044-75.719c-2.044-30.144-30.037-44.96-30.037-44.96-11.544-5.723-29.219-9.095-45.156-8.278-16.653.818-20.74 8.788-27.585 23.911-5.517 12.263-15.222 23.707-15.222 68.26z" transform="translate(37.697 37.68)"/>
                <path fill="#FFE6D5" fill-rule="nonzero" d="M178.516 35.593L177.3 51.041s13.077 1.728 9.326 13.72c-3.75 11.993-12.671 6.708-12.671 6.708s-2.23 30.693-33.657 20.937c-24.938-7.724-23.418-40.145-20.275-67.89 1.318-10.57 11.354-17.888 21.897-15.855l18.856 3.658c10.948 2.135 18.551 12.095 17.74 23.274z" transform="translate(37.697 37.68)"/>
                <path fill="#FFE6D5" fill-rule="nonzero" d="M177.303 64.32l-8.359 45.203c-1.913 10.157-11.883 16.658-21.853 14.322-9.265-2.235-15.207-11.377-13.495-20.823l4.532-25.902 39.175-12.8z" transform="translate(37.697 37.68)"/>
                <path fill="#333" fill-rule="nonzero" d="M143.303 64.19s0 .103-.106.308c-.106.206-.317.514-.74.72-.424.206-1.058.103-1.587-.308-.53-.309-.952-1.029-1.164-1.748-.529-1.44-.423-3.29-.317-5.141.106-1.851.212-3.908.317-6.067.318-4.318.635-8.226.846-11.105.318-2.879.53-4.627.635-4.524.106 0 .212 1.748.106 4.627-.106 2.88-.317 6.786-.529 11.105-.106 2.16-.317 4.216-.423 6.066-.106 1.851-.317 3.496 0 4.833.211 1.337 1.27 1.954 2.01 1.85.634-.102.846-.616.952-.616zM132.308 89.328c.101-.105 1.52.945 4.154 2.206 2.533 1.26 6.383 2.52 10.74 2.625 4.356.105 8.206-1.05 10.84-2.1 2.634-1.155 4.154-2.206 4.256-1.996.1.105-1.318 1.366-3.952 2.731-2.634 1.365-6.585 2.626-11.145 2.52-4.559-.104-8.51-1.575-11.043-3.045-2.634-1.47-3.951-2.836-3.85-2.94zM138.317 71.292c.103-.099.818.596 2.145 1.192 1.328.596 3.37 1.092 5.515.496 2.145-.695 3.575-2.283 4.188-3.475.715-1.29.817-2.184 1.021-2.184.102 0 .307.993-.306 2.482-.613 1.49-2.145 3.376-4.596 4.17s-4.903.1-6.23-.794c-1.43-.993-1.839-1.887-1.737-1.887zM178.303 66.156c0-.2.84-.2 1.785-.702.525-.302.945-.703 1.155-1.306.21-.602 0-1.305-.63-1.506-.42-.2-.945 0-1.05.201l-.945-.502c1.155-2.41 2.205-4.116 2.415-4.016.21.1-.42 2.008-1.47 4.418l-.945-.502c.21-.402.63-.603 1.05-.703.42-.1.84-.1 1.26.1.63.201 1.05.703 1.26 1.205.21.502.105 1.105-.105 1.506-.315.904-1.155 1.406-1.68 1.607-1.26.602-2.1.3-2.1.2zM154.303 35.86c0-.084.289-.337.867-.421.578-.17 1.446-.17 2.41.084.964.253 1.735.591 2.12 1.014.482.337.675.675.579.76-.193.169-1.35-.591-2.892-.93-1.542-.422-2.988-.253-3.084-.506zM127.307 31.528c-.098-.235 1.474-1.411 3.636-1.176 2.162.235 3.538 1.647 3.341 1.882-.197.353-1.572-.47-3.44-.706-1.965-.235-3.439.353-3.537 0z" transform="translate(37.697 37.68)"/>
                <path fill="#B63B03" fill-rule="nonzero" d="M177.369 51.504c-8.82-8.577-11.486-22.973-13.845-35.022-8.204 5.922-19.485 7.454-28.92 3.778-2.974 3.472-6.46 6.535-10.357 8.985-1.128.715-2.359 1.43-3.692 1.021-1.539-.408-2.154-2.144-2.461-3.675-1.129-4.8-1.129-10.007.512-14.601 1.641-4.595 5.23-8.781 9.845-10.517 4.923-1.94 10.563-1.123 15.69.204 7.795 2.042 15.179 5.105 22.152 9.087 6.769 3.983 13.332 9.19 16.204 16.44 2.871 7.249 2.871 21.441-4.103 25.117" transform="translate(37.697 37.68)"/>
                <path fill="#FFF" fill-rule="nonzero" d="M125.53 111.639c8.585-3.355-57.227 20.945-57.227 20.945l6.642 44.737 25.037-8.134 25.548-57.548z" transform="translate(37.697 37.68)"/>
                <path fill="#FFE6D5" fill-rule="nonzero" d="M42.273 94.877s0-.206.101-.411c1.111-3.598 9.091-29.703 6.364-29.086-2.93.617-9.798 20.041-10.303 19.939-.404 0 5.96-21.686 3.535-21.995-2.424-.308-8.283 19.939-8.283 19.939s4.04-19.22 1.515-19.63c-2.525-.411-6.364 17.266-6.364 17.266s3.03-14.902.707-15.21c-1.414-.206-14.242 36.588-14.242 36.588.303 0 23.435 9.044 23.435 9.044s2.02-2.878 4.444-6.578l-.909-9.97c.101 0 0 0 0 .104z" transform="translate(37.697 37.68)"/>
                <path fill="#333" fill-rule="nonzero" d="M171.026 197.32c-.1 0-.301-1.222-.602-3.465-.301-2.242-.702-5.605-1.204-9.58-1.003-8.154-2.207-19.366-3.612-31.8-.3-3.16-.501-6.218 0-9.276.401-2.955 1.605-5.81 3.311-7.95 1.706-2.242 3.813-3.873 5.92-4.994 2.106-1.223 4.314-1.936 6.32-2.344 4.014-.815 7.324-.612 9.632-.408 1.104.102 2.007.306 2.609.408.602.102.903.204.903.204 0 .102-1.204-.102-3.512-.204-2.207-.102-5.518-.204-9.431.713-1.906.51-4.013 1.223-6.02 2.345-2.006 1.223-4.013 2.752-5.518 4.892-1.605 2.038-2.709 4.688-3.11 7.542-.402 2.854-.301 5.912 0 8.97 1.304 12.434 2.508 23.646 3.31 31.8.402 4.077.703 7.338.904 9.682.1 2.14.1 3.466.1 3.466z" transform="translate(37.697 37.68)"/>
                <path fill="#241352" fill-rule="nonzero" d="M168.303 194.378c0-.321 11.902.749 26.55 2.354 14.649 1.606 26.55 3.21 26.449 3.532 0 .321-11.902-.75-26.55-2.354-14.65-1.713-26.45-3.211-26.45-3.532z" transform="translate(37.697 37.68)"/>
                <path fill="#E60000" fill-rule="nonzero" d="M7.995 38.321L1.303 92.203 59.303 93.321 59.303 42.082z" transform="translate(37.697 37.68)"/>
                <path fill="#FFF" d="M29.086 53.551c4.859 1.924 7.06 6.949 5.922 11.555l11.46 7.442c1.51.98 1.928 3.04.93 4.578-.999 1.537-3.05 1.993-4.56 1.012l-1.816 2.795c-.998 1.538-3.05 1.993-4.56 1.013-1.51-.98-1.928-3.04-.93-4.578l1.816-2.795-5.97-3.877c-3.24 2.525-7.803 2.88-11.385.554-5.133-3.333-6.056-10.747-1.418-15.425 2.737-2.772 6.951-3.672 10.511-2.274zm-6.219 7.364c-1.048 1.614-.585 3.793 1.03 4.841 1.614 1.049 3.793.586 4.84-1.029 1.05-1.614.586-3.793-1.028-4.841-1.615-1.049-3.793-.586-4.842 1.029z" transform="translate(37.697 37.68)"/>
                <path fill="#333" fill-rule="nonzero" d="M128.438 46.288c-.256-.107-.086-.963 0-2.033.085-.963.085-1.819.34-1.926.256-.107.596.856.511 2.033-.085 1.284-.596 2.14-.851 1.926zM154.482 49.315c-.269-.1-.18-1.199-.09-2.497.09-1.299.18-2.397.359-2.497.269 0 .627 1.099.538 2.497 0 1.498-.538 2.596-.807 2.497z" transform="translate(37.697 37.68)"/>
                <path fill="#E60000" fill-rule="nonzero" d="M150.206 70.975c.408 1.316-.51 2.725-2.14 3.195-1.63.47-3.26-.188-3.667-1.504-.408-1.315.51-2.724 2.14-3.194 1.528-.47 3.26.188 3.667 1.503z" opacity=".18" transform="translate(37.697 37.68)"/>
                <path fill="#E60000" fill-rule="nonzero" d="M166.303 54.269c0 2.286-3.057 4.052-7 4.052-3.845 0-7-1.766-7-3.948s3.056-4.052 7-4.052c3.845 0 7 1.766 7 3.948zM136.303 56.271c0 2.285-3.057 4.05-7 4.05-3.845 0-7-1.765-7-3.946 0-2.18 3.056-4.05 7-4.05 3.845-.104 7 1.662 7 3.946z" opacity=".13" transform="translate(37.697 37.68)"/>
                <path fill="#333" fill-rule="nonzero" d="M179.617 30.136s-.584.416-.486 0c.194-.415.456-1.868.262-2.699-.195-.83-.398-1.435-.982-2.37-.487-1.037-1.024-2.08-1.608-3.325-1.168-2.284-2.26-4.894-2.941-6.45-.682-1.558-.59-1.867-.492-1.971.097 0 .407.413 1.186 1.97.778 1.557 1.85 3.634 3.017 5.918.584 1.141 1.07 2.283 1.558 3.322.486 1.038.876 1.972 1.07 2.906.195.83.098 1.661-.194 2.076-.195.52-.39.727-.39.623z" transform="translate(37.697 37.68)"/>
                <path fill="#999" fill-rule="nonzero" d="M103.14 178.438L97.303 255.551 178.303 266.321 172.773 177.321z" transform="translate(37.697 37.68)"/>
                <path fill="#999" fill-rule="nonzero" d="M109.303 126.534L109.303 188.321 118.303 184.729 118.303 114.321 109.303 117.195M183.868 117.32s-36.358 4.68-25.562 63.984l9.064 1.017s-10.286-55.743 23.933-59.71l-7.435-5.29z" transform="translate(37.697 37.68)"/>
                <path fill="#FFE6D5" fill-rule="nonzero" d="M172.78 186.32s12.42 73.165-5.089 71.433c-57.514-5.91-91.82-6.725-91.82-6.725l-9.568 19.87c-.204 3.363 109.023 34.646 126.532 24.354 12.419-7.337 32.167-27.003 23.209-85.8l-2.138-19.666-41.125-3.465z" transform="translate(37.697 37.68)"/>
                <path fill="#FFF" fill-rule="nonzero" d="M204.79 137.767s13.538 12.9 16.513 61.554l-52.31-5.688s-8.718-82.986 35.796-55.866z" transform="translate(37.697 37.68)"/>
                <path fill="#FFE6D5" fill-rule="nonzero" d="M82.468 250.933s-27.95-9-26.94-6c.908 3 5.75 3.104 10.493 7.862 0 0-31.179.31-29.665 3 1.514 2.587 21.795 3.311 21.896 3.725.1.414-22.199 1.034-21.694 3.414.504 2.483 21.29 1.862 21.29 1.862s-19.272 2.069-18.869 4.759c.404 2.586 18.163.724 18.163.724s-14.833 1.758-14.43 4.138c.203 1.448 43.49 1.759 43.59 2.897-.1.517-3.834-26.38-3.834-26.38" transform="translate(37.697 37.68)"/>
                <path fill="#333" fill-rule="nonzero" d="M118.568 179.32c.105 0 .525 1.2.735 3.4 0 1.1-.105 2.5-.84 3.9-.42.7-.944 1.3-1.678 1.8s-1.679.9-2.623.9c-1.993 0-3.567-1.2-4.406-2.4-.944-1.3-1.154-2.6-1.364-3.7-.21-2.2 0-3.5.105-3.5.21 0 .21 1.3.63 3.4.21 1 .63 2.2 1.363 3.3.84 1 2.099 2 3.672 2 1.469 0 2.728-1 3.357-2.1.735-1.1.84-2.4.944-3.4.105-2.2-.105-3.6.105-3.6z" transform="translate(37.697 37.68)"/>
                <path fill="#333" fill-rule="nonzero" d="M112.121 179.789c.112.112-.223.562-.111 1.238.111.337.223.675.557.9.223.225.67.112 1.004-.113.335-.225.558-.675.447-.9 0-.338-.335-.675-.558-.788-.558-.337-1.004-.225-1.116-.337 0-.113 0-.226.335-.338.223-.113.67-.225 1.227 0 .446.112 1.115.563 1.339 1.35.223.788-.224 1.689-.893 2.139-.67.45-1.673.563-2.342 0-.67-.563-.781-1.238-.67-1.688 0-.563.223-.9.446-1.126.112-.337.224-.337.335-.337zM166.805 177.329c.103-.104.72.826.412 2.273-.103.723-.515 1.55-1.236 2.273-.722.723-1.752 1.343-2.988 1.446-1.236 0-2.37-.517-3.09-1.137-.722-.723-1.237-1.446-1.443-2.17-.412-1.446.103-2.375.206-2.375.206 0 0 .93.515 2.066.206.62.721 1.24 1.34 1.756.618.517 1.442.827 2.369.827.927 0 1.751-.517 2.266-1.033.619-.517 1.03-1.24 1.237-1.86.515-1.24.206-2.066.412-2.066z" transform="translate(37.697 37.68)"/>
                <path fill="#333" fill-rule="nonzero" d="M160.417 175.913c-.114-.098.457-.592 1.6-.592.571 0 1.257.197 1.828.79.229.296.458.691.458 1.086 0 .395-.115.79-.343 1.186-.229.395-.572.691-1.143.79-.457.197-1.029.197-1.486 0-.457-.198-.686-.494-.914-.79-.114-.297-.114-.593-.114-.89.114-.493.457-.888.8-.987.342-.099.571 0 .571 0 0 .099-.114.198-.229.296-.114.198-.228.395-.228.692 0 .296.228.691.571.79.343.099.8-.099 1.143-.494.343-.395.343-.79 0-1.185-.228-.296-.686-.593-1.143-.692-.685-.098-1.257.1-1.371 0z" transform="translate(37.697 37.68)"/>
                <g fill="#333" fill-rule="nonzero" opacity=".95">
                    <path d="M45.638 6.586c-.304 0-.406-1.824-.609-3.952 1.016.912.305.304.508.507h-.812c-.203 0-.508 0-.71-.101-.508 0-.914-.102-1.32-.203-.711-.101-1.219-.304-1.219-.405 0-.102.508-.304 1.219-.304.406 0 .812-.102 1.32-.102H45.536l.508.507c.304 2.23-.102 4.154-.407 4.053zM39.65 20.771c-.204-.203.811-1.621 1.928-3.445 1.117-1.824 2.03-3.343 2.233-3.14.305.1-.203 1.823-1.32 3.748-1.218 1.925-2.639 3.04-2.842 2.837zM26.046 27.864c0-.304 1.625-.71 3.655-1.317 2.03-.608 3.654-1.216 3.756-1.014.101.203-1.32 1.317-3.452 2.027-2.131.608-3.959.608-3.959.304zM11.022 24.723c.204-.203 1.726.506 3.655 1.216 1.929.81 3.654 1.317 3.553 1.62 0 .305-1.827.203-3.96-.607-2.03-.81-3.45-1.925-3.248-2.23zM1.48 12.665c.305-.101 1.015 1.52 2.03 3.344 1.016 1.824 1.93 3.344 1.625 3.546-.203.203-1.523-1.114-2.64-3.04-.913-1.925-1.218-3.748-1.015-3.85zM4.729 1.115c0 .304-1.828.506-4.06.405 1.116-.81.304-.203.507-.405v.304l-.102.405c0 .202-.101.506-.101.71-.102.404-.102.81-.203 1.215-.102.71-.305 1.114-.406 1.114-.102 0-.305-.506-.305-1.215V2.33c0-.202 0-.506.102-.81l.101-.405V.709C.465.507-.245 1.115.77.304c2.233.203 3.959.507 3.959.81zM19.448 1.621c0 .304-1.624.507-3.654.405-2.03-.1-3.655-.405-3.655-.607 0-.304 1.624-.507 3.655-.406 2.03.102 3.654.304 3.654.608zM34.066 2.23c0 .303-1.624.506-3.655.404-2.03-.1-3.654-.405-3.654-.608 0-.304 1.624-.506 3.654-.405 2.03 0 3.756.304 3.655.608z" transform="translate(37.697 37.68) translate(113.303 204.32)"/>
                </g>
                <path d="M41.853 94.92v-.3c-.1.2-.1.4-.1.4 0-.1.1-.1.1-.1z" transform="translate(37.697 37.68)"/>
                <path fill="#FFE6D5" fill-rule="nonzero" d="M52.92 88.32c-3.123 0-4.684 4.276-10.617 7.358l.936 9.643c4.893-6.86 11.763-17 9.681-17z" transform="translate(37.697 37.68)"/>
            </g>
        </g>
    </svg>
</template>

<script>
export default {}
</script>

<style lang="scss">

</style>